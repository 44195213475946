
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: "Poppins";
        font-weight: 200;
        src: url("assets/fonts/Poppins-ExtraLight.ttf");
    }
    @font-face {
        font-family: "Poppins";
        font-weight: 300;
        src: url("assets/fonts/Poppins-Light.ttf");
    }
    @font-face {
        font-family: "Poppins";
        font-weight: 400;
        src: url("assets/fonts/Poppins-Regular.ttf");
    }
    @font-face {
        font-family: "Poppins";
        font-weight: 500;
        src: url("assets/fonts/Poppins-Medium.ttf");
    }
    @font-face {
        font-family: "Poppins";
        font-weight: 600;
        src: url("assets/fonts/Poppins-Bold.ttf");
    }
    @font-face {
        font-family: "Poppins";
        font-weight: 700;
        src: url("assets/fonts/Poppins-ExtraBold.ttf");
    }
}

.rays {
	-webkit-animation: rays 8000ms infinite;
	        animation: rays 8000ms infinite;
}

 @-webkit-keyframes rays {
    0% {
      -webkit-transform: scale(.95);
              transform: scale(.95);
    }
    50% {
      -webkit-transform: scale(1.05);
              transform: scale(1.05);
    }
    100% {
        -webkit-transform: scale(.95);
                transform: scale(.95);
    }
  }
  @keyframes rays {
    0% {
      -webkit-transform: scale(.95);
              transform: scale(.95);
    }
    50% {
      -webkit-transform: scale(1.05);
              transform: scale(1.05);
    }
    100% {
        -webkit-transform: scale(.95);
                transform: scale(.95);
    }
  }
  